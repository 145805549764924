<script setup>
import VSelect from '@/components/ui/form/VSelect'
import ButtonSubmit from '@/components/ui/buttons/ButtonSubmit'

import { defineProps, ref, computed, inject, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useSetPattern } from '@/use/Base/useSetPattern'
import useLoading from '@/use/Base/useLoading'
import { LOT_CONTROLLER } from '@/utils/consts'
import { goToPage } from '@/utils/goRoute'

defineProps({
  titlePage: {
    type: String,
    required: true
  }
})

const select = ref(null)
const router = useRouter()
const store = useStore()
const LotController = inject(LOT_CONTROLLER)

const id = computed(() => {
  return router.currentRoute.value.params.id
})

if (id.value) {
  select.value = id.value
}

const { isLoading, startLoading, finishLoading } = useLoading()

const { getPatterns } = useSetPattern()
onMounted(getPatterns)

const getOptions = computed(() => store.getters['lot/getPattern'])

const goTemplate = () => goToPage('create-trade-layout-id', { id: select.value })

async function destroyPatternHandler () {
  try {
    startLoading()
    await LotController.destroyPatternLot(select.value)
    await getPatterns()
    await goToPage('create-trade-layout')
  } finally {
    finishLoading()
  }
}
</script>

<template>
  <h3 class="block__subtitle mb-40 mt-40">ВЫБРАТЬ ШАБЛОН</h3>

  <VSelect
    placeholder="Выберите шаблон"
    v-model="select"
    id="template"
    name="template"
    :options="getOptions"
  />

  <div class="flex gap-10">
    <button
      class="btn btn-primary btn-bg-green"
      @click="goTemplate"
    >
      Открыть
    </button>

    <ButtonSubmit
      v-if="select"
      text="Удалить шаблон"
      class="btn-bg-transparent"
      :is-loading="isLoading"
      @click="destroyPatternHandler"
    />
  </div>

  <router-view />
</template>
